import { observer } from "mobx-react-lite";

import CitizensDrawerStore from "components/Citizens/store/Citizens.drawer.store";
import BaseModal from "components/Modals/BaseModal";

const CitizenConfirmationModal = () => {
	const handleCancel = () => {
		CitizensDrawerStore.setIsModalVisible(false);
	};

	const handleOk = () => {
		CitizensDrawerStore.setIsModalVisible(false);
		CitizensDrawerStore.setShouldExitEditMode("all", true);
	};

	return (
		<BaseModal
			isVisible={CitizensDrawerStore.isModalVisible}
			title="Несохраненные изменения будут потеряны"
			description="Вы внесли изменения и не сохранили их"
			status="delete"
			okText="Продолжить"
			cancelText="Отменить"
			onOk={handleOk}
			onExit={handleCancel}
			onCancel={handleCancel}
		/>
	);
};

export default observer(CitizenConfirmationModal);
