export const ROUTES = {
	LOGIN: "login",
	BUILDINGS: "buildings",
	PROFILE: "profile",
	RECEIPTS: "receipts",
	COUNTERS: "counters",
	CITIZENS: "citizens",
	ACCESS: "access-request",
	CHAT: "chat",
	ANNOUNCEMENT: "announcement",
	EMPLOYEE: "employee",
	POLLS: "polls",
	UPDATE: "update-data",
};
