import React, { FC, memo, useEffect, useState } from "react";

import styled from "styled-components";
import { showToast, Upload } from "tap2visit-ui-kit";

import useDownloadedFiles from "components/Citizens/hooks/useDownloadedFiles";

import { CitizenImgWrapper, CitizenImgWrapperTitle } from "../style/Citizen.styled";
import CitizensDrawerStore from "../store/Citizens.drawer.store";

interface ICitizenFiles {
	disabled?: boolean;
	fileIds?: string[];

	newFiles?: File[];
	setNewFiles?: React.Dispatch<React.SetStateAction<File[]>>;

	needToRemoveFileIds?: string[];
	setNeedToRemoveFileIds?: React.Dispatch<React.SetStateAction<string[]>>;

	title?: string;

	background?: string;
}

const CitizenFiles: FC<ICitizenFiles> = memo((props) => {
	const [error, setError] = useState(false);
	const [newFiles, setNewFiles] = useState<any[]>(props.newFiles);
	const downloadedFiles = useDownloadedFiles({ fileIds: props.fileIds });

	useEffect(() => {
		setNewFiles(
			props.newFiles?.map((v) => ({
				file: v,
				error: false,
			})),
		);
	}, [props.newFiles]);

	const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files[0];
		const convert = (file.size / (1024 * 1024)).toFixed(2);
		if (Number(convert.split(".")[0]) > 20) {
			showToast({
				description: "Размер фотографии превышает 20 Mb",
				type: "danger",
			});
			return;
		}

		const updatedNewFiles = [
			...newFiles,
			{
				file: file,
				error: false,
				errorSize: Number(convert.split(".")[0]) > 20 ? true : false,
			},
		];

		setNewFiles(updatedNewFiles);
		props.setNewFiles(updatedNewFiles.map((fileObject) => fileObject.file));
		setError(false);
		props.setNewFiles(updatedNewFiles.map((v) => v.file));
	};

	const onRemoveNewFiles = (fileIdx: number) => {
		const updatedNewFiles = [...props.newFiles.slice(0, fileIdx), ...props.newFiles.slice(fileIdx + 1)];
		props.setNewFiles(updatedNewFiles);
	};

	const onRemoveDownloadedFile = (fileId: string) => {
		setError(false);
		CitizensDrawerStore.setHasUnsavedData(true);
		const newArrNeedToRemoveFileIds = [...props.needToRemoveFileIds, fileId];
		props.setNeedToRemoveFileIds(newArrNeedToRemoveFileIds);
	};

	const getFilteredDownloadFilesWithoutDeleted = () => {
		if (props.disabled) {
			return downloadedFiles.data;
		}
		return downloadedFiles.data?.filter((fileObject) => !props.needToRemoveFileIds?.includes(fileObject.fileId));
	};

	return (
		<CitizenImgWrapper background={props.background}>
			{props.title && <CitizenImgWrapperTitle>{props.title}</CitizenImgWrapperTitle>}
			<FilesContainer>
				{getFilteredDownloadFilesWithoutDeleted()?.map((fileObject) => (
					<Upload
						onErrorAddFile={() => {
							setError(true);
						}}
						error={error}
						files={[fileObject.file]}
						onChangeFiles={(e) => undefined}
						accept="image/jpeg,image/png"
						type="image"
						disabled={props.disabled}
						onRemove={() => onRemoveDownloadedFile(fileObject.fileId)}
					/>
				))}
				{newFiles?.map((file: any, idx) => (
					<Upload
						onErrorAddFile={() => {
							setNewFiles(
								newFiles.map((v, index) => ({
									...v,
									error: idx === index ? true : v.error,
								})),
							);
							setError(true);
						}}
						error={file.error || file.errorSize}
						files={[file.file]}
						onChangeFiles={(e) => undefined}
						accept="image/jpeg,image/png"
						type="image"
						disabled={props.disabled}
						onRemove={() => onRemoveNewFiles(idx)}
					/>
				))}
				{!props.disabled && (
					<Upload
						onErrorAddFile={() => {
							setError(true);
						}}
						error={error}
						onChangeFiles={onChangeFiles}
						accept="image/jpeg,image/png,image/jpg,image/webp"
						type="image"
						disabled={props.disabled}
					/>
				)}
			</FilesContainer>
		</CitizenImgWrapper>
	);
});

export default CitizenFiles;

const FilesContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
`;
