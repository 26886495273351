import React, { FC } from "react";

import styled from "styled-components";
import { Space, Tag, getUid, Text } from "tap2visit-ui-kit";

import CitizenBuildingObjectStore from "components/Citizens/store/CitizenBuildingObject.store";
import { IBuildingObject, IClientBuildingObjectsIds } from "interfaces/IBuilding";
import { IAddress } from "interfaces/IGeocoder";
import { getBuildingObjectType } from "utils/getBuildingObjectType";
import getRoleTranslate from "utils/getRoleTranslate";

import { CitizenRealtyDivider, CitizenWrapper } from "../../../../components/Citizens/style/Citizen.styled";
import CitizenDetachModals from "../Citizen.detachModals";

interface IRealEstateRowsItem {
	citizenBuildingObjects: IBuildingObject[];
	objectsIds: IClientBuildingObjectsIds;
	citizenAliases: IAddress[];
}

const DROPDOWN_BUTTON_ID = getUid("dropdown_real_estate_button");

const RealEstateRowsItem: FC<IRealEstateRowsItem> = (props) => {
	const [buildingIdForDeletion, setBuildingIdForDeletion] = React.useState<string>();
	const [isVisibleDeleteOwnerModal, setIsVisibleDeleteOwnerModal] = React.useState(false);
	const [isVisibleDeleteTrustedClientModal, setIsVisibleDeleteTrustedClientModal] = React.useState(false);

	const openBuildingDrawer = (buildingObject, address: IAddress) => {
		CitizenBuildingObjectStore.openDrawer(buildingObject, address.value);
	};

	const alias = props.citizenAliases?.find((value) => value.id === props.objectsIds.aliasId);
	const buildingObject = props.citizenBuildingObjects?.find((value) => value.id === props.objectsIds.buildingObjectId);

	const onClickDetachUser = (buildingObject: IBuildingObject, roles: IClientBuildingObjectsIds) => {
		setBuildingIdForDeletion(buildingObject.id);

		const isBuildingOwner = roles.roles.some((role) => role.name === "OWNER");

		if (isBuildingOwner) {
			setIsVisibleDeleteOwnerModal(true);
		} else {
			setIsVisibleDeleteTrustedClientModal(true);
		}
	};

	return (
		<>
			<CitizenDetachModals
				isVisibleDeleteOwnerModal={isVisibleDeleteOwnerModal}
				setIsVisibleDeleteOwnerModal={setIsVisibleDeleteOwnerModal}
				buildingIdForDeletion={buildingIdForDeletion}
				isVisibleDeleteTrustedClientModal={isVisibleDeleteTrustedClientModal}
				setIsVisibleDeleteTrustedClientModal={setIsVisibleDeleteTrustedClientModal}
			/>

			<CitizenWrapper isRealEstateWrapper onClick={() => openBuildingDrawer(buildingObject, alias)}>
				<Space align="start" size={1} direction="column">
					<Text type="table-cell-l-medium">{getBuildingObjectType(buildingObject?.objectType)}</Text>
					<Text type="table-cell-m-regular" color="rgba(18, 18, 18, 0.6)">
						{alias?.value}
					</Text>
				</Space>
				<FlexRow>
					<RoleButtonsWrapper>
						{props.objectsIds.roles?.map((role) => <Tag key={role.name} text={getRoleTranslate(role.name)} />)}
					</RoleButtonsWrapper>

					{/* Temporary hidden */}
					{/* <CitizenDropdown
						dropdownId={DROPDOWN_BUTTON_ID}
						onClick={() => onClickDetachUser(props.buildingObject, props.roles)}
						items={[
							{
								icon: MinusIcon,
								meta: {
									id: "1",
								},
								text: "Открепить недвижимость",
							},
						]}
						style={{ width: "250px", overflow: "hidden" }}
					/> */}
				</FlexRow>
			</CitizenWrapper>
			<CitizenRealtyDivider />
		</>
	);
};

export default RealEstateRowsItem;

const DropDownContainer = styled.div`
	position: relative;
`;

const SpaceWrapper = styled(Space)`
	display: flex;
	flex-wrap: wrap;
`;

const RoleButtonsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px; /* Adjust gap between buttons if necessary */
	justify-content: flex-end;
`;

const FlexRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
