import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Tab, colors } from "tap2visit-ui-kit";

import { mainSectionsHeader } from "pages/accessRequest/constants";
import AccessRequestTabStore, { TActiveTab } from "pages/accessRequest/store/AccessRequestTab.store";

const Tabs = () => {
	const onTabClick = (key: TActiveTab) => {
		AccessRequestTabStore.setActiveTab(key);
	};

	return (
		<>
			<Tab
				activeKey={AccessRequestTabStore.activeTab}
				onTabClick={onTabClick}
				style={{ borderBottom: "none" }}
				withLine={true}
				paddingItem={"12px 0"}
				items={mainSectionsHeader ?? []}
				direction={"horizontal"}
				gap={24}
			/>
			<Border />
		</>
	);
};

export default observer(Tabs);

const Border = styled.div`
	border-bottom: 0.5px solid ${colors.strokeDivider};
	position: relative;
	left: -22px;
	top: -1px;
	width: calc(100% + 22px * 2);
`;
