import { makeAutoObservable } from "mobx";

class AccessRequestRejectModalStore {
	isVisible = false;
	requestId: string | undefined = undefined;
	isCancelMode = false;

	constructor() {
		makeAutoObservable(this);
	}

	showModal = (args: { requestId: string; isCancelMode?: boolean }) => {
		this.isVisible = true;
		this.requestId = args.requestId;
		this.isCancelMode = args.isCancelMode;
	};

	closeModal = () => {
		this.isVisible = false;
		this.requestId = undefined;
		this.isCancelMode = false;
	};
}

export default new AccessRequestRejectModalStore();
