import styled from "styled-components";

export const MainWrapper = styled.div`
	padding: 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const MainPaddingContent = styled.div`
	padding: 16px 16px 0 16px;
`;
