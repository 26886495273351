import type { MenuProps } from "antd";

import { isVisibleFunctionality } from "constants/constants.common";
import { ReactComponent as Chat } from "public/Chat.svg";
import { ReactComponent as AnnouncemetPoint } from "public/menu/announcement_point.svg";
import { ReactComponent as Build } from "public/menu/build.svg";
import { ReactComponent as Counter } from "public/menu/counter.svg";
import { ReactComponent as File } from "public/menu/file.svg";
import { ReactComponent as Home } from "public/menu/home.svg";
import { ReactComponent as List } from "public/menu/list.svg";
import { ReactComponent as Staff } from "public/menu/staf.svg";
import { ReactComponent as Update } from "public/menu/update.svg";
import { ReactComponent as User } from "public/menu/user.svg";
import { ReactComponent as PollSvg } from "public/poll.svg";
import { ROUTES } from "routes/constants/routes.constants";

type TMenuItem = Required<MenuProps>["items"][number];

const menuOrderAndVisibility = [
	{ label: "Жилые комплексы", key: ROUTES.BUILDINGS, icon: <Build />, visible: () => isVisibleFunctionality },
	{ label: "Профиль УК", key: ROUTES.PROFILE, icon: <Home />, visible: () => isVisibleFunctionality },
	{ label: "Квитанции", key: ROUTES.RECEIPTS, icon: <File />, visible: () => true },
	{ label: "Счетчики", key: ROUTES.COUNTERS, icon: <Counter />, visible: () => true },
	{ label: "Жители", key: ROUTES.CITIZENS, icon: <List />, visible: () => isVisibleFunctionality },
	{ label: "Запрос на доступ", key: ROUTES.ACCESS, icon: <User />, visible: () => isVisibleFunctionality },
	{ label: "Чат с жителями", key: ROUTES.CHAT, icon: <Chat />, visible: () => true },
	{ label: "Объявления", key: ROUTES.ANNOUNCEMENT, icon: <AnnouncemetPoint />, visible: () => isVisibleFunctionality },
	{ label: "Сотрудники", key: ROUTES.EMPLOYEE, icon: <Staff />, visible: () => true },
	{ label: "Опросы и голосование", key: ROUTES.POLLS, icon: <PollSvg />, visible: () => isVisibleFunctionality },
	{ label: "Обновление данных", key: ROUTES.UPDATE, icon: <Update />, visible: () => isVisibleFunctionality },
];

const menuItems: TMenuItem[] = menuOrderAndVisibility
	.filter((item) => item.visible())
	.map(({ label, key, icon }) => ({ label, key, icon }));

export { menuItems, type TMenuItem };
