import { observer } from "mobx-react-lite";

import UpdateDataTabs from "./components/UpdateData.tabs";
import UpdateDataTabsStore from "./store/UpdateData.tabs.store";
import UpdateDataTable from "./Table/UpdateDataTable";
import { MainWrapper } from "./UpdateData.styled";

const UpdateData = () => (
	<MainWrapper>
		<UpdateDataTabs />
		{/* {UpdateDataTabsStore.activeTab === "download" && <UpdateDataDownload />} */}
		{UpdateDataTabsStore.activeTab === "history" && <UpdateDataTable />}
	</MainWrapper>
);

export default observer(UpdateData);
