import { IUpdatedDataTable, IUpdatedDataTableParams } from "interfaces/IArcusIntegration";
import apiClient from "./api.client";
import dayjs from "dayjs";

export type TGetUpdatedDataTableApi = (args: { params: IUpdatedDataTableParams }) => Promise<IUpdatedDataTable>;

export const getUpdatedDataTableApi: TGetUpdatedDataTableApi = async (args) => {
	try {
		const { createdBySearch, statuses, types, createDateTimeStart, createDateTimeEnd, page, size, sort, ...otherParams } = args.params;

		const path = "arcus-integration/v1/import-tasks/page";

		const params: Record<string, any> = {
			page: page || 0,
			size: size || 20,
			...otherParams,
		};

		const formattedCreateDateTimeStart = createDateTimeStart ? dayjs("04.10.2024 12:31", "DD.MM.YYYY").toISOString() : undefined;

		if (statuses?.length) {
			params.statuses = statuses.join(",");
		}

		if (createdBySearch) {
			params.createdBySearch = createdBySearch;
		}

		if (types?.length) {
			params.types = types.join(",");
		}

		if (formattedCreateDateTimeStart) {
			params.createDateTimeStart = formattedCreateDateTimeStart;
		}
		if (createDateTimeEnd) {
			params.createDateTimeEnd = createDateTimeEnd;
		}

		if (sort?.length) {
			params.sort = sort.map((s) => `${s.key},${s.direction}`).join("&");
		}

		const res = await apiClient.get(path, {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
