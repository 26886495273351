import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import { colors, Drawer, showToast } from "tap2visit-ui-kit";

import { updateUserByIdApi } from "api/api.clients";
import { createClientApi } from "api/api.gateway";
import { Spacer } from "components/layout/Spacer";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { getIsVisibleUserFieldsAndTextButton } from "utils/getIsVisibleUserField";

import DrawerUserEdit from "../../pages/citizens/components/Drawer.userEdit";
import DrawerUserInfoCollapse from "../../pages/citizens/components/Drawer.userInfoCollapse";

import ChangeInitialInfo from "./components/ChangeInitialInfo";
import useUploadCitizenFiles from "./hooks/useUploadCitizenFiles";
import CitizenCreateStore from "./store/CitizenCreate.store";
import CitizensDrawerStore from "./store/Citizens.drawer.store";
import { getTitleCitizensDrawer } from "./utils/Drawer.utils";

const CitizensDrawer: FC = () => {
	const { isVisibleAvatarAndInitialInfo, isVisibleEditFields, textOk } = getIsVisibleUserFieldsAndTextButton(
		CitizensDrawerStore.drawerMode,
	);

	const uploadPassportFiles = useUploadCitizenFiles({
		needToRemoveFileIds: [],
		newFiles: CitizenCreateStore.clientFiles.passport,
	});
	const uploadInsuranceFiles = useUploadCitizenFiles({
		needToRemoveFileIds: [],
		newFiles: CitizenCreateStore.clientFiles.insuranceNumber,
	});
	const uploadInnFiles = useUploadCitizenFiles({
		needToRemoveFileIds: [],
		newFiles: CitizenCreateStore.clientFiles.inn,
	});

	const createUser = useMutation({
		mutationFn: createClientApi,
	});

	const updateUser = useMutation({
		mutationFn: updateUserByIdApi,
	});

	React.useEffect(() => {
		if (!isVisibleEditFields) {
			CitizenCreateStore.dispose();
		}
	}, [isVisibleEditFields]);

	const onOk = async () => {
		if (CitizensDrawerStore.drawerMode === "visible") {
			CitizensDrawerStore.openDrawer({ mode: "edit", user: CitizensDrawerStore.selectedUser });
			return;
		}

		if (CitizensDrawerStore.drawerMode === "create") {
			const { isCanCreateCitizen } = CitizenCreateStore.checkInputsValidation();

			if (!isCanCreateCitizen) {
				showToast({
					description: "Нужно заполнить все обязательные поля",
					type: "danger",
				});
				CitizenCreateStore.showErrorInputs();
				return;
			}

			const [passportFilesIds, insuranceFilesIds, innFilesIds] = await Promise.all([
				uploadPassportFiles(),
				uploadInsuranceFiles(),
				uploadInnFiles(),
			]);

			const documentsWithFilesIds = CitizenCreateStore.documents.map((doc) => {
				switch (doc.type) {
					case "PASSPORT":
						return {
							...doc,
							fileIds: passportFilesIds,
						};
					case "INN":
						return {
							...doc,
							fileIds: innFilesIds,
						};
					case "SNILS":
						return {
							...doc,
							fileIds: insuranceFilesIds,
						};
					default:
						return doc;
				}
			});

			await createUser.mutateAsync({
				body: {
					client: CitizenCreateStore.client,
					contacts: CitizenCreateStore.contacts,
					documents: documentsWithFilesIds,
				},
			});
		}

		if (CitizensDrawerStore.drawerMode === "edit") {
			const dataInputs = CitizensDrawerStore.selectedUser;

			await updateUser.mutateAsync({
				path: { id: dataInputs.id },
				body: {
					contacts: dataInputs.contacts.filter((contact) => contact.value),
					firstName: dataInputs.firstName,
					lastName: dataInputs.lastName,
					inn: dataInputs.inn,
					insuranceNumber: dataInputs.insuranceNumber,
					middleName: dataInputs.middleName,
				},
			});
		}

		CitizensDrawerStore.openDrawer({ mode: "visible", user: CitizensDrawerStore.selectedUser });
		queryClient.invalidateQueries([QueriesKeys.clients]); // TODO update users table after changes
	};

	const handleDrawerClose = () => {
		if (CitizensDrawerStore.hasUnsavedData) {
			CitizensDrawerStore.setIsModalVisible(true);
		} else {
			CitizensDrawerStore.closeDrawer();
		}
	};

	return (
		<Drawer
			title={getTitleCitizensDrawer(CitizensDrawerStore.drawerMode)}
			visible={CitizensDrawerStore.isVisibleDrawer}
			onClose={handleDrawerClose}
			onCancel={handleDrawerClose}
			onOk={onOk}
			disableOk={false}
			showCancel={CitizensDrawerStore.drawerMode !== "visible"}
			showOk={CitizensDrawerStore.drawerMode !== "visible"}
			width="700px"
			textOk={CitizensDrawerStore.drawerMode !== "visible" && textOk}
			textCancel={CitizensDrawerStore.drawerMode !== "visible" && "Отмена"}
			// headerSlot={CitizensDrawerStore.drawerMode === "visible" && <CitizensEditHeaderSlot />}
			backgroundBody={colors.surfaceNeutralBg4}>
			{isVisibleAvatarAndInitialInfo && <ChangeInitialInfo />}
			{CitizensDrawerStore.drawerMode === "visible" && (
				<>
					<Spacer px={16} />
					<DrawerUserInfoCollapse />
				</>
			)}
			{isVisibleEditFields && (
				<>
					<Spacer px={16} />
					<DrawerUserEdit />
				</>
			)}
		</Drawer>
	);
};

export default observer(CitizensDrawer);
