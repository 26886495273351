import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { BoldUserdelete, BoldUserchecked5, BoldRefresh1 } from "t2v-icons";
import { Button } from "tap2visit-ui-kit";

import { IRequestUser } from "interfaces/IRequest";
import { useApproveRequest, useRestoreRequest } from "pages/accessRequest/api/api.request";
import AccessRequestRejectModalStore from "pages/accessRequest/components/ModalCommentRejection/store/AccessRequestRejectModal.store";
import AccessRequestCanApproveRequestStore from "pages/accessRequest/store/AccessRequestCanApproveRequest.store";

interface IActionButtons {
	requestUser: IRequestUser;
	compareUserId: string | undefined;
	onRequestSuccess: () => void;
}

const ActionButtons: FC<IActionButtons> = (props) => {
	const { mutate: approveRequest } = useApproveRequest({
		requestUserId: props.requestUser.id,
		compareUserId: props.compareUserId,
		onRequestSuccess: props.onRequestSuccess,
	});

	const { mutate: restoreRequest } = useRestoreRequest({
		requestUserId: props.requestUser.id,
		onRequestSuccess: props.onRequestSuccess,
	});

	const isDisabled = () => !props.requestUser.canBeRestored || props.requestUser.status !== "CANCELED";

	const onOpenCancelRejectCommentModal = () => {
		AccessRequestRejectModalStore.showModal({ requestId: props.requestUser.id });
	};

	const onOpenRejectCommentModal = () => {
		AccessRequestRejectModalStore.showModal({ requestId: props.requestUser.id, isCancelMode: true });
	};

	return (
		<>
			{props.requestUser.status === "NEW" && (
				<WrapperUserButton>
					<Button
						icon={(args) => <BoldUserdelete {...args} style={{ height: "18px", width: "18px" }} />}
						variant="red"
						onClick={onOpenCancelRejectCommentModal}>
						Отклонить
					</Button>

					<AcceptButton
						icon={(args) => <BoldUserchecked5 {...args} style={{ height: "18px", width: "18px" }} />}
						disabled={!AccessRequestCanApproveRequestStore.canApproveRequest}
						onClick={() => approveRequest()}>
						Принять
					</AcceptButton>
				</WrapperUserButton>
			)}

			{props.requestUser.status === "CANCELED" && (
				<WrapperUserButton>
					<Button size="small" typeButton="secondary" onClick={onOpenRejectCommentModal}>
						Комментарий
					</Button>
					<Button
						disabled={isDisabled()}
						size="small"
						shape="square"
						typeButton="primary"
						icon={BoldRefresh1}
						onClick={() => restoreRequest()}
					/>
				</WrapperUserButton>
			)}
		</>
	);
};

export default observer(ActionButtons);

const WrapperUserButton = styled.div`
	display: flex;
	button {
		&:first-child {
			margin-right: 8px;
		}

		&:last-child {
			div {
				padding: 0px 4px 0px 6px !important;
			}
		}
	}
`;

const AcceptButton = styled(Button)`
	padding: 0px 8px !important;
`;
