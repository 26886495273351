import React, { FC } from "react";

import { isVisibleFunctionality } from "constants/constants.common";
import CitizensHeader from "pages/citizens/Citizens.header";
import { CountersHeader } from "pages/counters/CountersHeader";
import EmployeeHeader from "pages/employee/Employee.header";
import PollsDetailHeader from "pages/polls/components/PollsDetail.header";
import PollsHeader from "pages/polls/Polls/Polls.header";
import PollsViewHeader from "pages/polls/PollsView/PollsView.header";
import ReceiptsHeader from "pages/receipts/components/Receipts.header";
import { UpdateHeader } from "pages/updateData/UpdateHeader";

import AccessRequestHeader from "../pages/accessRequest/AccessRequest.header";
import AnnouncementCreateHeader from "../pages/announcemets/components/AnnouncementCreateHeader";
import ExclamationHeader from "../pages/announcemets/components/AnnouncementHeader";
import BuildingsHeader from "../pages/buildings/Buildings.header";
import BuildingsEntranceHeader from "../pages/buildings/BuildingsEntrance.header";
import BuildingsHouseHeader from "../pages/buildings/BuildingsHouse.header";
import ProfileHeader from "../pages/profileUK/components/ProfileHeader";

import { ROUTES } from "./constants/routes.constants";

const RoutesHeader: FC = () => {
	if (location.pathname.startsWith(`/${ROUTES.ANNOUNCEMENT}/edit`)) {
		return <AnnouncementCreateHeader mode="edit" />;
	}

	if (location.pathname.startsWith(`/${ROUTES.POLLS}/edit`) || location.pathname.startsWith(`/${ROUTES.POLLS}/create`)) {
		if (isVisibleFunctionality) {
			return <PollsDetailHeader />;
		}
	}

	if (location.pathname.startsWith(`/${ROUTES.POLLS}/view`)) {
		if (isVisibleFunctionality) {
			return <PollsViewHeader />;
		}
	}

	switch (location.pathname) {
		case `/${ROUTES.BUILDINGS}`:
			if (isVisibleFunctionality) {
				return <BuildingsHeader />;
			}
			break;
		case `/${ROUTES.EMPLOYEE}`:
			return <EmployeeHeader />;
		case `/${ROUTES.PROFILE}`:
		case `/${ROUTES.PROFILE}/edit`:
			if (isVisibleFunctionality) {
				return <ProfileHeader />;
			}
			break;
		case `/${ROUTES.CITIZENS}`:
			return <CitizensHeader />;
		case `/${ROUTES.ACCESS}`:
			if (isVisibleFunctionality) {
				return <AccessRequestHeader />;
			}
			break;
		case `/${ROUTES.ANNOUNCEMENT}`:
			if (isVisibleFunctionality) {
				return <ExclamationHeader />;
			}
			break;
		case `/${ROUTES.ANNOUNCEMENT}/create`:
			if (isVisibleFunctionality) {
				return <AnnouncementCreateHeader mode="create" />;
			}
			break;
		case `/${ROUTES.POLLS}`:
			if (isVisibleFunctionality) {
				return <PollsHeader />;
			}
			break;
		case `/${ROUTES.RECEIPTS}`:
			return <ReceiptsHeader />;
		case `/${ROUTES.UPDATE}`:
			return <UpdateHeader />;
		case `/${ROUTES.COUNTERS}`:
			return <CountersHeader />;
	}

	if (isVisibleFunctionality) {
		if (location.pathname.includes(`/${ROUTES.BUILDINGS}/house`)) return <BuildingsHouseHeader />;
		if (location.pathname.includes(`/${ROUTES.BUILDINGS}/entrance`)) return <BuildingsEntranceHeader />;
		if (location.pathname.includes(`/${ROUTES.BUILDINGS}/floor`)) return <BuildingsEntranceHeader />;
	}

	return <></>;
};

export default RoutesHeader;
