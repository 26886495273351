import { makeAutoObservable } from "mobx";

export type TUpdateDataTabs = "download" | "history";

class UpdateDataTabsStore {
	activeTab: TUpdateDataTabs = "download";
	onlyActiveStatus = false;

	constructor() {
		makeAutoObservable(this);
	}

	setActiveTab = (tabKey: TUpdateDataTabs) => {
		this.activeTab = tabKey;
	};

	toggleOnlyActiveStatus = () => {
		this.onlyActiveStatus = !this.onlyActiveStatus;
	};
}

export default new UpdateDataTabsStore();
